export interface User {
    id: string;
    firstName?: string;
    lastName?: string;
    role: string;
    type: UserType;
    email: string;
    avatar?: string;
    status?: string;
    phone?: string;
    occupation?: string;

    verifiedBy?: string;
    verifiedOn?: Date;
    updatedOn?: Date;
    createdOn?: Date;
    lastLoginOn?: Date;
    lastPasswordResetOn?: Date;

    // to be completed later
    children?: any[];
    cards?: any[];
    notifications?: Notifications;
    businessDetails?: any;
}

export interface UserResponse {
    total: number;
    pageNumber: number;
    pageSize: number;
    data: User[];
  }

  export interface Notifications {
    offers: boolean;
    upcomingEvents: boolean;
    PersonalizedOffers: boolean;
  }

  export const Notifications = {
    offers: 'oferte',
    upcomingEvents: 'evenimente',
    personalizedOffers: 'oferte personalizate'
  };

  export enum UserType {
    parent = 'parent',
    teacher = 'teacher',
    order_operator = 'order_operator'
  }